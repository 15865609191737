// global store: import stores directly to avoid dev server circular dependency issues with HMR
import {
  actions as route$actions,
  hooks as route$hooks,
  triggers as route$triggers,
} from ":shared/components/route/SHARED/store";
import { actions as toast_group$actions } from ":src/components/alert/toast-group/store";
import { actions as confirm_dialog$actions } from ":src/components/popup/confirm-dialog/store";
import { actions as modal$actions } from ":src/components/popup/modal/store";
import { hooks as platform$hooks, actions as platform$actions } from ":src/modules/platform/store";

//
import { createOnBreakpoint } from ":shared/helpers/dom-events";
import { unocss$ } from ":public-dynamic/unocss";
export default {
  hooks: {
    route: route$hooks,
    dom: {
      ...createOnBreakpoint(unocss$.breakpoints.dynamic),
    },
    platform: {
      ...platform$hooks,
    },
  },
  triggers: {
    route: route$triggers,
  },
  actions: {
    alert: {
      ...toast_group$actions,
    },
    popop: {
      ...confirm_dialog$actions,
      ...modal$actions,
    },
    route: {
      ...route$actions,
    },
    platform: platform$actions,
  },
};
